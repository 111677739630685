$blanc: #fff;
$noir: #000;

$light: #89d2dc;
$dark: #08414d;

$primary: #1e839a;
$secondary: #44c8e6;
$tertiary: #cbff00;

// Prestations
$aides-sociales: #c868a6;
$cheques-vacances: #5fdabe;
$maisons-forestieres: #47864c;
$voyages: #3d66db;
$activites-nationales: #d85353;
$activites-region: #ad7494;
$activites-regions: #b5cf40;
$hiver: #87c8ea;
$printemps: #b5cf40;
