
.card.card-profil-apas-onf-panel{
    display : none;
}
.card-change-apas-onf-panel {

  @media screen and (min-width: 1170px) {
        position: fixed;
        bottom: 14px;
        right: 20px;
        z-index: 100;
        width: 29%;
        border: 7px solid $primary;
        box-shadow: 0 0px 25px 1px rgba(0, 0, 0, 0.5);
        .card-footer {
            display:block;
        }
    }

  .card-footer {
      display:none;
  }
  .card-change-apas-onf-body {
      height: auto;
      max-height: 90vh;
      overflow-y: auto;;
  }
}
