.prestation {
    color: $blanc;
    margin-top: 1rem;
    position: relative;
    border-radius: 3px;
    &:before {
        content: "";
        opacity: .3;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-repeat: no-repeat;
        background-size: auto 90%;
        @include media-breakpoint-up(md){
            background-size: auto;
        }
    }
    a {
        color: $blanc;
        text-decoration: none;
    }
    h2 {
        line-height: 1em;
        font-weight: 300;
        strong {
            font-weight: 700;
        }
        @include media-breakpoint-up(md){
            font-size: 3rem;
        }
    }
}

.prestation-link {
    position: relative;
    display: flex;
    align-items: center;
    padding: 1rem;
    padding-left: 2rem;
    height: 200px;
    transition: background-color .2s;
    &:hover {
        background-color: rgba($noir, .3);
    }
    @include media-breakpoint-up(md){
        padding-left: 1rem;
        height: 300px;
        justify-content: center;
    }
}

.prestation-border-left {
    border-left: 4px solid $blanc;
    padding-left: 1rem;
}

.prestation-border-right {
    border-right: 4px solid $blanc;
    padding-right: 1rem;
}

// .aides-sociales {
//     background-color: $aides-sociales;
//     &:before {
//         background-image: url("../../public/img/front/famille.png");
//         background-position: left bottom;
//     }
// }

// .cheques-vacances {
//     background-color: $cheques-vacances;
//     &:before {
//         background-image: url("../../public/img/front/palmiers.png");
//         background-position: left bottom;
//     }
// }
//
.maisons-forestieres {
    // background-color: $maisons-forestieres;
    // h2 {
    //     margin-bottom: 1rem;
    // }
    // &:before {
    //     background-image: url("../../public/img/front/maison.png");
    //     background-position: center top;
    //     background-size: 100% auto;
    // }
    .prestation-body {
        padding-top: 5.25rem;
        padding-right: 1rem;
        padding-left: 2rem;
    }
    @include media-breakpoint-up(md){
        height: 300px;
        .prestation-body {
            padding-left: 0;
            border-left: 0 none;
        }
        .saisons {
            transform: translateX(-2.5rem)
        }
        &:before {
            background-position: left bottom;
            background-size: auto;
        }
    }
    .saison {
        font-size: 3rem;
        font-weight: 200;
        .icon {
            text-align: center;
            margin-right: .5rem;
        }
        transition: color .2s;
        &:hover {
            color: $blanc;
        }
        &.hiver:not(:hover) {
            color: $hiver;
        }
        &.printemps:not(:hover) {
            color: $printemps;
        }
    }
}

.hero-carousel-container {
    $heroHeight: none;
    padding : 12px;
    background-color:$dark;
    max-height: $heroHeight;
    .text-bloc{
        height: $heroHeight;
    }
    .chapo-container{
        max-height: 50vh;
    }
    .background-trans{
        background-color: #00000082;
    }
  @media (min-width: 768px){
    $heroHeight: 400px;
    background-color:$dark;
    max-height: $heroHeight;
    padding : 0;
    .text-bloc{
        height: $heroHeight;
    }
  }
}

//
// .voyages {
//     background-color: $voyages;
//     &:before {
//         background-image: url("../../public/img/front/bateau.png");
//         background-size: 80%;
//         background-position: 150% 80%;
//         opacity: .2;
//     }
// }
//
// .activites-nationales {
//     background-color: $activites-nationales;
//     &:before {
//         background-image: url("../../public/img/front/public.png");
//         background-position: left bottom;
//         background-size: 100% auto;
//     }
// }
//
// .activites-region {
//     background-color: $activites-region;
//     &:before {
//         background-image: url("../../public/img/front/cycliste.png");
//         background-position: left bottom;
//     }
// }
//
// .activites-regions {
//     background-color: $activites-regions;
//     &:before {
//         background-image: url("../../public/img/front/skieur.png");
//         background-position: left bottom;
//     }
// }
