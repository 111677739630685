.page-content {
    h1 {
        font-size: 2rem;
        font-weight: 300;
        margin-bottom: 1.5rem;
    }
}

.page-profil {
    background-position: 0 230px;
    .page-content {
        position: relative;
        background: rgb(30,131,154);
        background: linear-gradient(200deg, rgba(30,131,154,1) 0%, rgba(10,85,99,1) 100%);
        background-attachment: fixed, fixed;
        min-height: calc(100vh - 230px);
        @include media-breakpoint-up(md){
            &:before {
                content: "";
                position: absolute;
                width: 230px;
                height: 100%;
                background-color: rgba($noir, .1);
            }
        }
        @include media-breakpoint-up(lg){
            min-height: auto;
        }
        color: $blanc;
        h1 {
            color: $blanc;
        }
    }
    .tab-content {
        @include media-breakpoint-up(sm){
            width: calc(100vw - 230px);
        }
    }
    .list-group-item {
        background-color: transparent;
        border-color: $blanc;
        font-size: 1.25rem;
        &:first-child,
        &:last-child {
            border-radius: 0;
        }
        small {
            font-size: .75rem;
            line-height: 1.875rem;
        }
    }
    .navbar-toggler {
        color: $blanc;
        &:focus {
            outline: 3px solid $secondary;
        }
    }
}

.side-menu {
    background-color: rgba($noir, .1);
    .nav-link {
        color: $blanc;
        line-height: 1.25em;
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-bottom-color: rgba($blanc, .5) !important;
        &.active {
            color: $secondary;
            font-weight: bold;
        }
        &:hover {
            color: $secondary;
        }
    }
    @include media-breakpoint-up(sm){
        background-color: transparent;
        width: 230px;
        height: 100%;
        padding: 2.5rem 1.875rem;
    }
    
}
