/* BOOTSTRAP */

// // Required
// @import "~bootstrap/scss/functions";
// @import "~bootstrap/scss/variables";
// @import "~bootstrap/scss/mixins";
//
// // Optional
// @import "~bootstrap/scss/reboot";
// @import "~bootstrap/scss/type";
// @import "~bootstrap/scss/images";
// @import "~bootstrap/scss/code";
// @import "~bootstrap/scss/grid";
// @import "~bootstrap/scss/navbar";
// @import "~bootstrap/scss/nav";
// @import "~bootstrap/scss/buttons";
// @import "~bootstrap/scss/utilities";
// @import "~bootstrap/scss/utilities";
// @import "~bootstrap/scss/list-group";
// @import "~bootstrap/scss/transitions";

@import "variables";
@import "~bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import '../../../node_modules/pikaday/css/pikaday.css';
@import '../../../node_modules/select2/dist/css/select2.css';
@import '../../../node_modules/jquery-wizard/dist/css/wizard.css';
@import '../../../node_modules/leaflet/dist/leaflet.css';
@import '../../../node_modules/slick-carousel/slick/slick-theme.css';
@import '../../../node_modules/slick-carousel/slick/slick.css';


/* CUSTOM */

// Libs
@import "../_toast";

@import "../_temp";
@import "../_subscription";

@import "../typehead";
@import "../select2";


@import '../form';
@import '../common';
// @import 'variables';


@import 'fonts';
@import 'apasonf-icons';
@import 'buttons';
@import 'page-header';
@import 'page-footer';
@import "pagination";

// Entities
@import 'navbar';
@import '../content';
@import 'content';
@import 'news';
@import 'prestations';
@import 'modal';
@import '_temp';
@import "../user";
@import "../message";
@import "../benefit";
@import "../installement";
@import "../subscription";
@import "../slider";
@import "../calendar";
@import "../leaflet";
@import "../images";

//
// @import "fonts";
// @import "common";
// @import "sidebar";
// @import "navs";
// @import "content";
// @import "address";
// @import "user";
// @import "temp";
// @import "email-box";
// @import "_timeline";
// @import "message";
// @import "form";
// @import "typehead";
// @import "benefit";
// @import "select2";
// @import "installement";
// @import "subscription";
