.app-navbar {
    background-color: $dark;
    padding-top: 10px;
    padding-left: 5px;
    padding-right: 5px;
    transition: bottom .25s;
    &.scrolled {
        bottom: -60px;
    }
    .nav-link {
        color: rgba($blanc, 0.5);
        padding: 0;
        line-height: 1em;
        width: 33%;
        &.active {
            color: $blanc;
        }
        &:hover {
            color: rgba($blanc, 0.5);
        }
    }
    i {
        width: 24px;
        height: 24px;
        margin: 0 auto;
        font-size: 20px;
    }
    .label {
        text-transform: uppercase;
        font-size: .75rem;
        padding: 0 12px;
        white-space: nowrap;
    }
}

nav.nav-top.env-local,
nav.nav-top.env-Preprod,
nav.nav-top.env-Dev{
  background-color: #dc3545 !important;
}
