.page-header {
  min-height: 230px;
  position: relative;
  user-select: none;
  z-index: 1;
  background-color: $blanc;
  a {
      transition: color .2s;
  }
  @include media-breakpoint-down(md){
    transition: all 0.3s ease;
    display: flex;

    position: fixed;
    bottom: -300%;
    flex-wrap: wrap-reverse;
    width: 100%;
    padding: 0 0 57px 0;
    box-shadow: 0px 3px 12px rgba(0,0,0,0.6);
    &.show{
      bottom: 0;
    }
    &.menu{
      .nav-user {
        display: none;
      }
    }
    &.account{
      .nav-top:not(.logo) .nav,
      .nav-content {
        display: none;
      }
    }
    .nav-top.logo{
      display: none;
    }
  }
}
.wrapper{
  @include media-breakpoint-down(md){
    padding: 190px 0 0 0;
  }
}

.nav-top {
    background: $dark;
    padding: 5px 0;
    .nav-link {
        color: $blanc;
        &.active {
            color: $secondary;
        }
        &:hover {
            color: $secondary;
        }
        .icon {
            vertical-align: text-bottom;
        }
        .label{
            font-size: 0.875rem;
        }
    }
    .user-nav-item {
        overflow: hidden;
        max-width: 0;
        opacity: 0;
        transition: all .25s;
        height: 40px;
    }
    .navbar-brand {
        max-width: 250px;
        transition: all .5s;
        top: 30px;
        left: 15px;
        .img-big {
            display: block;
        }
        .img-small {
            display: none;
        }
        @include media-breakpoint-down(md){
          position: fixed !important;
          top: 0;
          width: 100%;
          background-color: $dark;
          display: block;
          max-width: none !important;
          max-height: 50px;
          left: 0  !important;
          img{
            margin: auto;
          }
      }
    }
    &.scrolled {
        .user-nav-item {
            max-width: 200px;
            opacity: 1;
        }
        .navbar-brand {
            top: 0;
            left: 15px;
            max-width: 60px;
            .img-big {
                display: none;
            }
            .img-small {
                display: block;
            }

        }
    }


    .nav-link-big {
        font-size: 1.5rem;
        line-height: 1rem;
        .label {
            font-size: 1.125rem;
        }
    }
    @include media-breakpoint-down(md){
      position: relative;
      width: 100%;
      background-color: #fff;
      padding: 0;
      z-index:0;
      .container{
        max-width: none;
        padding: 0;
        ul{
          li{
            width: 50%;
            border-bottom: 1px solid #979797;
            color: #bcbcbb;
            a{
              padding: 15px 10px;
              color: #bcbcbb;
              font-size: 18px;
              text-align: center;
              .icon{
                color: $blanc;
                background-color: #bcbcbb;
                padding: 5px;
                border-radius: 50%;
              }
              &.active {
                color: #928470;
                .icon{
                  background-color: #928470;
                }
              }

            }

          }
          li:nth-child(2n+1){
            border-right : 1px solid #979797;
          }
        }
      }

    }
}

.nav-top-sm {
    padding: 0;
    background: $blanc;
    border-top: 30px solid $dark;
    .navbar-brand {
        display: block;
        margin-top: -20px;
        margin-right: auto;
        margin-left: auto;
        padding-top: 0;
        padding-bottom: 0;
    }
}

body:not(.page-accueil){
    .nav-top-sm {
        .navbar-brand {
            img {
                max-height: 100px;
                @include media-breakpoint-up(sm){
                    max-height: unset;
                }
            }
        }
    }
}

.nav-user {
    background: $secondary;
    color: $blanc;
    padding-top: 50px;
    .user-profile {
        height: 60px;
    }
    .icon-user {
        display: inline-flex;
        width: 2.5rem;
        height: 2.5rem;
        background: $blanc;
        color: $secondary;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 1.5rem;
    }
    .btn {
        color: $dark;
        &:hover {
            color: $blanc;
            background-color: $dark;
        }
    }
    @include media-breakpoint-down(md){
      background: $blanc;
      padding-top: 0;
      color: #bcbcbb;
      z-index:1;
      .container{
        max-width: none;
        .user-profile{
          height: auto;
          .user-information{
            justify-content: center;
            span,strong{
              display: block;
              margin: 10px 4px;
              &.icon.icon-user{
                background-color:#bcbcbb;
                color:$blanc;
                padding: 0.4rem;
              }
            }
          }
          .d-lg-none .content-nav .nav{
            .col-9{
              &:nth-child(1),
              &:nth-child(2){
                border-top: 1px solid #979797;
              }
              &:nth-child(2n+1){
                border-right: 1px solid #979797;
              }
              &:last-child{
                border-right: 0px solid #979797;
              }
              width: 50% !important;
              max-width: none;
              border-bottom: 1px solid #979797;
              padding: 5px 10px;
              color: #bcbcbb;
              font-size: 15px;
              flex: auto;
              a{
                color: #bcbcbb;
              }
            }
          }
          .btn.btn-sm.btn-rounded{
            background-color: #dc3545;
            color: white;
            display: block;
            margin: 15px auto;
            max-width: 300px;
          }
        }
      }
    }
}

.nav-content {
    padding-top: 2.1rem;
    padding-bottom: 1.8rem;
    .nav {
        // margin-top: 2.5rem;
    }
    .nav-link {
        color: $dark;
        line-height: 1.25em;
        &:hover {
            color: $secondary;
        }
    }
  @include media-breakpoint-down(md){
    width: 100%;
    padding: 0;
    z-index:1;
    background-color: $blanc;
    .container{
      max-width: none;
      padding: 0;
      .nav{
        display: block;
        .nav-item{
          a.nav-link{
            width: 100% !important;
            border-bottom: 1px solid #979797;
            padding: 15px 10px;
            color: #bcbcbb;
            font-size: 18px;
          }
        }
      }
    }
  }
}

$bar-width: 20px;
$bar-height: 2px;
$bar-spacing: 7px;
$margin: 7px;


.menu-wrapper {
  margin:  0 auto  $margin auto ;
  width: $bar-width;
	height: $bar-height + $bar-spacing*2;
	cursor: pointer;

}

.hamburger-menu,
.hamburger-menu:after,
.hamburger-menu:before {
  width: $bar-width;
	height: $bar-height;
}

.hamburger-menu {
	position: relative;
	transform: translateY($bar-spacing);
	background: rgba(255, 255, 255, 1);
	transition: all 0ms 300ms;

  &.animate {
    background: rgba(255, 255, 255, 0);
  }
}

.hamburger-menu:before {
	content: "";
	position: absolute;
	left: 0;
	bottom: $bar-spacing;
	background: rgba(255, 255, 255, 1);
	transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu:after {
	content: "";
	position: absolute;
	left: 0;
	top: $bar-spacing;
	background: rgba(255, 255, 255, 1);
	transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu.animate:after {
	top: 0;
	transform: rotate(45deg);
	transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
}

.hamburger-menu.animate:before {
	bottom: 0;
	transform: rotate(-45deg);
	transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
}

$bk-color : $dark;
$size : 20px;
$icon-color : #84a0a6;
.account-menu{
  height : $size;
  width : $size;
  background-color: $bk-color;
  margin : 0 auto 4px auto;
  .head-item{
    background-color: $icon-color;
    height : 45%;
    width : 45%;
    border-radius : 50%;
    margin:0 auto 5% auto;
    transition : all 0.3s ease;
    transform-origin : 50%;
    position: relative;
  }
  .body-item{
    background-color: $icon-color;
    height : 50%;
    width : 100%;
    border-radius :  50%  50% 0 0 ;
    margin:auto;
    transition : all 0.3s ease;
    transform-origin : 50%;
    position: relative;
  }
  &.animate{
    .body-item,
    .head-item{
      width : 100%;
      height : 10%;
      border-radius :  0;
    }
    .body-item{
      transform : rotate(45deg) ;
      top: 30%;
    }
    .head-item{
      transform : rotate(-45deg);
      top: 45%;
    }
  }
}
