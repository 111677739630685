.page-footer {
    user-select: none;
    border-top: 1px solid $blanc;
    a {
        transition: color .2s;
    }
}
.page-footer-top {
    background-color: $dark;
    color: rgba($blanc, .7);
    a {
        color: $blanc;
        &:hover {
            color: $secondary;
            text-decoration: none;
        }
    }
}

.page-footer-bottom {
    background-color: $primary;
    color: $blanc;
    a {
        color: $blanc;
        &:hover {
            color: $dark;
            text-decoration: none;
        }
    }
}