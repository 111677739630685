
.btn-blanc {
    background-color: $blanc;
}
.btn-rounded {
    border-radius: 19px;
}

.btn-sm {
    &.btn-rounded {
        border-radius: 15px;
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.btn-outline-secondary {
    border-color: $secondary;
    color: $secondary;
    &:hover {
        background-color: $secondary;
        color: $blanc;
        border-color: $secondary;
    }
    &:not(:disabled):not(.disabled){
        &:active,
        &.active {
            background-color: $dark;
            border-color: $secondary;
            color: $secondary;
        }
    }
}
